<template>
	<div class="hx-order-nine">
		<div class="left" align="left">
			<img src="../../assets/ysmimg/ysmorderoneimg.png" class="infoimg" width="90px" height="110px"
				style="vertical-align: middle;">
			<div>
				<h3>管理员反馈</h3>
				<div align="left" style="margin-bottom: 0.625rem;">请查看您的管理员建议</div>
			</div>
		</div>


		<!-- 右侧 -->
		<div class="right">
			<!-- 第一部分患者信息 -->
			<div class="hzinfo" align="left">
				 <h4>订单问题反馈</h4> 
				 <div v-show="showAnswer" style="margin-top: 20px;" class="hzinfo" align="left">
				 	<!-- <h4>订单问题反馈</h4> -->
				 	 <div id="neirong1"></div>
				 </div>
				 <div id="neirong"></div>
			</div>
			
			
			<!-- 第二部分:上和下颌 -->
			<!-- <div>

				<div class="con">
					<div class="right">
						<p >
						<h4>矫治器类型</h4><span>{{leixing}}</span></p>
						<p>
						<h4>治疗目标</h4><span>{{mubiao}}</span></p>
						<p>
						
						<h4 style="display: block;!important">上传X光片</h4>
						<p style="margin-left: 30px;"><span class="tip" v-show="xRay.length==0" style="color: #b25900 !important;font-weight: bold;">尚未上传X光片</span></p>
						<p  v-show="xRay.length>0">
						  <p style="margin-left: 30px;" v-for="(item,index) in xRay"><span>{{item.type}}</span><span>{{item.date}}</span></p>
						</p>
						
						<h4 style="display: block;!important">上传口扫</h4>
							<p style="margin-left: 30px;"><span class="tip" v-show="ks.length==0" style="color: #b25900 !important;font-weight: bold;">尚未上传口扫照片</span></p>
							<p  v-show="ks.length>0">
							  <p style="margin-left: 30px;" v-for="(item,index) in ks"><span>{{item.type}}</span><span>{{item.date}}</span></p>
							</p>
							
						 <h4 style="display: block;!important">上传口内照片</h4>
						    <p style="margin-left: 30px;"><span class="tip" v-show="kn.length==0" style="color: #b25900 !important;font-weight: bold;">尚未上传上传口内照片</span></p>
							<p  v-show="kn.length>0">
							  <p style="margin-left: 30px;" v-for="(item,index) in kn"><span>{{item.type}}</span><span>{{item.date}}</span></p>
							</p>
							
						<h4 style="display: block;!important">上传患者照片</h4>
						    <p style="margin-left: 30px;"><span class="tip" v-show="mx.length==0" style="color: #b25900 !important;font-weight: bold;">尚未上传面向照片</span></p>
							<p  v-show="mx.length>0">
							  <p style="margin-left: 30px;" v-for="(item,index) in mx"><span>{{item.type}}</span><span>{{item.date}}</span></p>
							</p>
						<!-- <h4>留&nbsp;&nbsp;&nbsp;&nbsp;言</h4><span>{{liuyan}}</span></p> -->
						<!-- <p>
							<h4>未填写信息</h4><span v-if="totalCompleted=='true'">无</span>
							<div style="padding-left: 120px;color: red;margin: 0px;" v-if="totalCompleted=='false'">
								<p style="margin: 0;"  v-for="(item,index) in wrongMessages">
									{{item}}
								</p>
							</div>
						</p> -->
						<div align="right" style="margin-top: 27px;">
							<el-button class="back" type="primary" @click="back">返回</el-button>
							<!-- <el-button class="nextStep" type="primary" @click="nextStepOrder">下一步</el-button> -->
						</div>
					</div>
				</div>
			</div> 


		</div>

	</div>
</template>

<script>
	export default {
		data: function() {
			return {
				showAnswer:false,
				info:'',
				wrongMessages:[],
				patientNum: '',
				patientBirthday: '',
				patientGender: '',
				leixing: '',
				mubiao: '',
				xRay:[],
				xRayCompleted: false,
				ks:[],
				ksCompleted: false,
				kn:[],
				knCompleted: false,
				mx:[],
				mxCompleted: false,
				yidongCompleted:false,
				liuyan:'',
				totalCompleted:false,
				ruleForm: {
					oid: ''
				},
				
				zuoshanglist: [], //储的左上哪些牙要移动
				youshanglist: [], //储的右上哪些牙要移动
				youxialist: [], //存储的右下哪些牙要移动
				zuoxialist: [], //储的左下哪些牙要移动
				checkList: [],
			}
		},
		methods: {
			//返回
			back(){
				this.$router.push("/hxmain/hxlatestorder")
			},
			//下一步
			nextStepOrder() {
				if(this.totalCompleted=="true"){
					this.$store.commit("setHxStepIndex",10);
					this.$router.push("/hxmain/hxorder/hxordereleven");
				}else{
					this.$alert("订单填写不完整","提示");
				}
			}
		},
		mounted: function() {
			this.$store.commit('setHxStepIndex', 11);
			//设置默认地址
			sessionStorage.setItem("location", "/hxmain/hxorder/hxordereleven")
			//获取当前添加的订单oid
			//获取oid
			// let oid=sessionStorage.getItem("oid");
			// if(oid==null){
			// 	this.$alert("请填写患者信息","提示");
			// 	this.$router.push("/addorder/orderone");
			// }else{
			// 	this.ruleForm.oid=oid;
			// }
			//设置返回路径
			this.$store.commit("setBackLocation", "/hxmain/hxorder/hxorderten");
		},
		created: function() {
			document.getElementsByClassName("saveb")[0].setAttribute("class","saveb die");
			document.getElementsByClassName("print")[0].setAttribute("class","print die");
			document.getElementsByClassName("fh")[0].setAttribute("class","fh show");
			/* document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
			 document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3"); */
			//如果是编辑订单要获取oid的值
			let oid = sessionStorage.getItem("oid");

			if (oid != "undefined" && oid != '' && oid != null) {
				this.ruleForm.oid = oid;
				this.$axios.get("/client/order/getOrderFeedback", {
					params: {
						oid: this.ruleForm.oid
					}
				}).then(res => {
					this.info = res.data.data.info;
					$("#neirong").html(res.data.data.info).css("color","#F18F57");
					console.log(res.data.data.showAnswer);
					console.log(res.data.data.textAnswer);
					this.showAnswer=res.data.data.showAnswer;
					$("#neirong1").html(res.data.data.textAnswer).css("color","#F18F57");
					// if (confirmInfo.patientNum != null && confirmInfo.patientNum != "") {
					// 	this.patientNum = confirmInfo.patientNum;
					// }
					// if (confirmInfo.patientBirthday != null && confirmInfo.patientBirthday != "") {
					// 	this.patientBirthday = confirmInfo.patientBirthday;
					// }
					// if (confirmInfo.patientGender != null && confirmInfo.patientGender != "") {
					// 	this.patientGender = confirmInfo.patientGender;
					// }
					// if (confirmInfo.jzqlx != null && confirmInfo.jzqlx != '') {
					// 	this.leixing = confirmInfo.jzqlx;
					// }
					// if (confirmInfo.planDoctor != null && confirmInfo.planDoctor != '') {
					// 	this.mubiao = confirmInfo.planDoctor;
					// }
					
					// 	this.xRay = confirmInfo.xray;
					
					
					// 	this.ks = confirmInfo.kousao;
					
					
					// 	this.kn = confirmInfo.kounei;
					
					
					// 	this.mx = confirmInfo.mianxiang;
					
					
					//留言
					// this.liuyan=confirmInfo.plan.liuyan;
					//未填写信息
					// this.wrongMessages=res.data.data.wrongMessages;
					// console.log(this.wrongMessages);
					//验证是否通过
					// this.totalCompleted=res.data.data.checked;
				}).catch(err => {
					this.$alert("系统出现异常,请联系管理员!");
				});
			}else{
				this.$alert('请填写患者信息', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.push("/hxmain/hxorder/hxorderone");
					}
				});
				
			}
		},
	}
</script>

<style scoped="scoped">
	 h4{
		 display: inline-block;
		 width: 100px;
		 text-align: right;
		 /* margin-right: 5px; */
		 margin: 0px;
	 }
	.hx-order-nine {
		width: 80%;
		display: flex;
		margin: 0px auto;
		margin-top: 3.125rem;
		padding-bottom: 5.125rem;
		justify-content: space-around;
	}

	h3 {
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #0D8EA4;
		margin-bottom: 20px;
		font-size: 24px;
	}

	.left {
		display: flex;
		width: 30rem;
		position: relative;
		justify-content: flex-start;
	}
.right{
	width: 560px;
}
	/* 按钮 */
	.nextStep {
		background-color: #0D8EA4 !important;
		border-color: #0D8EA4 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}

	.back {
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #0D8EA4 !important;
		color: #0D8EA4 !important;
	}

	.hzinfo span ,.right span{
		font-size: 0.9375rem;
		display: inline-block;
		margin-left: 1.25rem;
	}

	/* 第二部分 */
	.con {
		position: relative;
		/* left: 9.55rem; */
		text-align: left;
	}

	/* 32颗牙 */
	.medical-report-ctrl {
		width: 100%;
	}

	.jianchaitem {
		color: #999999;
		font-size: 12px;
	}

	table {
		border: 0;
		background-color: transparent;
		width: 100%;
		border-spacing: 0;
		border-collapse: collapse;
	}

	table.container-table {
		border: solid 4px #ccc;
	}

	/* 左L右R的背景颜色 */
	table.container-table td.label-column {
		text-align: left;
		padding: 5px;
		background-color: #ccc;
	}

	/* 右R */
	table.container-table td.label-column:first-child {
		padding-right: 10px !important;
	}

	/* 左L */
	table.container-table td.label-column:last-child {
		padding-left: 10px !important;
	}

	table td {
		text-align: center !important;
		vertical-align: middle !important;
		border: 0 !important;
		padding: 0 !important;
	}

	.medical-report-ctrl table.report-table tr.select-row td div {
		height: 24px;
		line-height: 1.5rem;
		border: solid 2px #ccc;
		font-size: 13px;
		text-align: center;
		padding-top: 3px;
		cursor: pointer;
	}

	table.report-table td div {
		border-top: 0 !important;
		border-bottom: 0 !important;
		min-width: 31px;
		white-space: nowrap;
	}

	.medical-report-ctrl table.report-table td:nth-child(1) div {
		border-left: 0 !important;
		min-width: 29px;
	}

	/* table.report-table中的第二行的tr下边框褐色 */
	.medical-report-ctrl table.report-table tr:nth-child(2) td {
		border-bottom: solid 2px #333 !important;
	}

	/* 序号行的td的背景颜色 */
	.medical-report-ctrl table.report-table tr.label-row td {
		height: 15px;
		background-color: #ccc;
	}

	/* 白色隔条 */
	td.label-column-space div {
		background-color: #fff;
		width: 0.1875rem;
		border-left: 0;
		border-right: 0;
	}

	/* 最后一个td的div */
	.medical-report-ctrl table.report-table td:nth-last-child(1) div {
		border-right: 0 !important;
		min-width: 29px;
	}

	/* 竖线 */
	.medical-report-ctrl table.report-table tr.label-row td:nth-child(9) {
		border-left: solid 2px #333 !important;
	}

	/* =======================28颗牙齿============================== */
	tr {
		display: table-row;
	}

	.teeth-selection-ctrl {
		background-color: white;

	}

	.teeth-selection-ctrl table.container-table {
		border: 0;
	}

	.teeth-selection-ctrl table.selection-table {
		border: solid 1px #ccc;
		border-left-width: 2px !important;
		border-right-width: 2px !important;
	}

	.teeth-selection-ctrl table td {
		text-align: center !important;
		vertical-align: middle !important;
		background-color: #ccc;
		padding: 0 !important;

	}

	.teeth-selection-ctrl table.container-table th {
		font-size: 12px;
		color: #999;
	}

	th {
		font-weight: bold;
	}

	.teeth-selection-ctrl table.selection-table tr.select-row td:first-child div {
		border-left-color: #ccc !important;
	}

	.teeth-selection-ctrl table.selection-table tr.select-row td div {
		height: 28px;
		font-size: 22px;
		line-height: 25px;
		font-weight: bold;
		text-align: center;
		vertical-align: middle;
		border-right-color: #fff !important;
		border-left-color: #fff !important;
		color: #fff;
		background-color: #CCCCCC;
	}

	.teeth-selection-ctrl table.selection-table td div {
		min-width: 41px;
		cursor: pointer;
		border: solid 1px #ccc;

	}

	.teeth-selection-ctrl table.selection-table td * {
		box-sizing: content-box;
	}

	.teeth-selection-ctrl table.selection-table tr.teeth-row td div {
		height: 37px;
		font-size: 13px;
		text-align: center;
		background-color: #fff;
	}

	.teeth-selection-ctrl table.selection-table tr:nth-child(2) {
		border-bottom: solid 2px #CCCCCC !important;
	}

	.teeth-selection-ctrl table.selection-table td:nth-child(7) div {
		border-right-width: 3px !important;
	}

	.teeth-selection-ctrl table.selection-table td:nth-last-child(1) div {
		border-right: 0 !important;
		min-width: 38px;
	}

	.infoimg {
		width: 5.625rem;
		height: 6.875rem;
	}

	img {
		border: 0;
		width: 2.3125rem;
		height: 2.3125rem;
	}
	#hei {
		filter: grayscale(100%);
		-webkit-filter: grayscale(100%);
		-moz-filter: grayscale(100%);
		-ms-filter: grayscale(100%);
		-o-filter: grayscale(100%);
		filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
		-webkit-filter: grayscale(1);
		pointer-events: none;
	}
	#neirong,#neirong1{
		padding-left: 50px;
	}
	.hei {
		filter: grayscale(100%);
		-webkit-filter: grayscale(100%);
		-moz-filter: grayscale(100%);
		-ms-filter: grayscale(100%);
		-o-filter: grayscale(100%);
		filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
		-webkit-filter: grayscale(1);
		pointer-events: none;
	}
</style>
